<template>
  <div id="container-apryse">
    <div class="navbar-apryse">
      <div class="nav-left-apryse">
        <div class="logo-nav-apryse">
          <img crossorigin="anonymous" :src="this.$logo"  alt class="px-2 img-fluid logo-jaybod-v3" />
        </div>
        <div class="info-item-apryse">
          <span class="item-name">{{item.file_name}}</span>
          <div class="item-location">
            <img class="mb-1" src="@/assets/images/icon/icon-folder-apryse.svg" alt="">
            <span class="ml-2">{{state == 'storage' ? 'My Files' : 'Group Files'}}</span>
            <span v-for="(breadcrumb, index) in breadcrumbs" :key="index">
              <i class="mx-2">/</i>
              {{breadcrumb.file_name.substring(0,15)}}{{breadcrumb.file_name.length > 15 ? '...' : ''}}
            </span>
          </div>
        </div>
      </div>
      <div class="nav-right-apryse">
        <b-dropdown :disabled="accessFile == false" id="dropdown-more-apryse" class="more-apryse">
          <template #button-content>
              <img src="@/assets/images/icon/btn-more.svg" alt/>
          </template>
          <b-dropdown-item-button @click="shareopennew()">
              <img src="@/assets/images/icon/share-apryse.svg" alt height="100%" />
              <span class="ml-3 default-text-style">Share</span>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="openDownload()">
              <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
              <span class="ml-3 default-text-style">Download</span>
          </b-dropdown-item-button>
        </b-dropdown>
        <button :disabled="accessFile == false" @click="openDesktopApp()" data-toggle="tooltip" title="" class="btn-apryse btn-open ml-4">Open</button>
        <button @click="goBack()" class="btn-apryse btn-close ml-4">Close</button>
      </div>
    </div>
    <div id="webviewer" ref="viewer"></div>
    <ModalShare />
    <Download />
    <DownloadApp />
  </div>
</template>

<script>
import WebViewer from "@pdftron/webviewer";
import ModalShare from '../../../components/widgets/modalShare.vue'
import Download from "../../../components/widgets/download.vue";
import DownloadApp from "../../../components/widgets/modalDownloadApp.vue";

export default {
  name: 'WebViewer',
  // props: {
  //   initialDoc: {
  //     type: String
  //   }
  // },
  data() {
    return {
      viewer: null,
      viewerInstance: null
      // hasNavigated: false
    };
  },
  components:{
    ModalShare,
    Download,
    DownloadApp
  },
  computed:{
    linkFile(){
      return this.$store.getters["storage/linkS3"]
    },
    userName(){
      return this.$store.getters['user/fullname']
    },
    item(){
      return this.$store.getters["storage/item"];
    },
    state(){
      return this.$store.getters['state/state'];
    },
    accessFile(){
      return this.$store.getters['storage/canAccessFile'];
    },
    breadcrumbs() {
      return this.$store.getters[this.$store.getters['state/state']+'/breadcumbs'].slice(-2)
    },
  },
  beforeMount() {
    if(this.$route.query.wgId != undefined){
      this.$store.dispatch("workgroup/retrieve", this.$route.query.wgId)
      .then(()=>{
        let id = this.$store.getters['user/id'];
        let group = this.$store.getters['workgroup/workgroup'];
        if(id == group.owner_id){
          this.$store.commit("storage/SET_CAN_ACCESS_FILE", true)
        }else{
          this.$store.commit("storage/SET_CAN_ACCESS_FILE", group.access_type != "View" ? true : false)
        }
      })
    } else {
      this.$store.commit("storage/SET_CAN_ACCESS_FILE", true)
    }
    this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id_f,directory:this.$route.query.dir_f})
    .then(()=>{
      this.initWebViewer();

    })
  },
  mounted() {
  },
  methods: {
    shareopennew(){
      this.$store.commit("storage/SET_ITEM",this.item);
      this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id_f,directory:this.$route.query.dir_f})
      .then(() => {
        this.$root.$emit('bv::show::modal', 'modal-share-file-v3')
      })
    },
    openDownload(){
      this.$store.commit("storage/SET_ITEM",this.item);
      this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id_f,directory:this.$route.query.dir_f})
      .then(() => {
        this.$root.$emit('bv::show::modal', 'modalDownload')
      })
    },
    openDesktopApp() {
      this.$store.commit("storage/SET_ITEM",this.item);
      this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id_f,directory:this.$route.query.dir_f})
      .then(() => {
        this.$root.$emit('bv::show::modal', 'modal-download-app')
      })
    },
    goBack() {
      window.history.back()
    },
    annotationSave(data) {
      let payload = {
        _id: this.$route.query.id_f,
        directory: this.$route.query.dir_f,
        xfdf: data
      }
      this.$store.dispatch('storage/annotationApryse', payload)
    },
    initWebViewer() {
      const path = `${process.env.BASE_URL}webviewer`;
      WebViewer({
        path,
        initialDoc: this.linkFile,
        licenseKey: `${process.env.VUE_APP_LICENSE_APRYSE}`,
        webviewerServerURL: `${process.env.VUE_APP_WEBVIEWER_SERVER_APRYSE}`,
        fullAPI: true
      }, this.$refs.viewer)
      .then(instance => {
        this.viewerInstance = instance
        const { documentViewer, annotationManager, Annotations } = this.viewerInstance.Core;

        // // Get the current user
        // const currentUser = annotationManager.getCurrentUser();
        // console.log('Current User:', currentUser);

        // Set the current user (for demonstration purposes)
        annotationManager.setCurrentUser(`${this.userName}`);

        // // Verify the change
        // const newUser = annotationManager.getCurrentUser();
        // console.log('New User:', newUser);

        documentViewer.addEventListener('documentLoaded', () => {
          const annotManager = documentViewer.getAnnotationManager();
          if (this.accessFile == false){
            instance.UI.disableFeatures([instance.UI.Feature.Annotations])
          }else{
            instance.UI.enableFeatures([instance.UI.Feature.Annotations])
          }
          // Fetch xfdfString from your backend
          this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id_f,directory:this.$route.query.dir_f})
          .then((res)=> {
            let xfdfString = res.annotation
            // Import the xfdfString into the WebViewer
            annotManager.importAnnotations(xfdfString);
            // console.log('Annotations loaded successfully')
          })

        })
        instance.UI.enableElements('toolsHeader')
        instance.UI.disableElement('searchButton');
        instance.UI.disableElement('ribbons');
        instance.UI.disableElement('toolsOverlay');
        instance.UI.disableElement('underlineToolGroupButton');
        instance.UI.disableElement('highlightToolGroupButton');
        instance.UI.disableElement('shapeToolGroupButton');
        instance.UI.disableElement('freeTextToolGroupButton');
        // instance.UI.disableElement('freeHandHighlightToolGroupButton');
        instance.UI.disableElement('squigglyToolGroupButton');
        instance.UI.disableElement('strikeoutToolGroupButton');
        instance.UI.disableElement('menuButton');
        instance.UI.disableElement('viewControlsButton');
        instance.UI.disableElement('panToolButton');
        instance.UI.disableElement('selectToolButton');
        instance.UI.disableElement('markInsertTextGroupButton');
        instance.UI.disableElement('markReplaceTextGroupButton');
        instance.UI.openElements([ 'leftPanel' ])
        instance.UI.setZoomLevel('100%');


        annotationManager.addEventListener('annotationChanged', async (annotations, action) => {
          const annotManager = documentViewer.getAnnotationManager()
          const xfdfString = await annotManager.exportAnnotations()
          if (action === 'add') {
            // console.log('this is a change that added annotations');
            this.annotationSave(xfdfString)
          } else if (action === 'modify') {
            // console.log('this change modified annotations');
            this.annotationSave(xfdfString)
          } else if (action === 'delete') {
            // console.log('there were annotations deleted');
            this.annotationSave(xfdfString)
          }

          annotations.forEach(annot => {
            // console.log('annotation page number', annot.PageNumber);
          });
        });
      })
      .catch(error => {
        console.error('Error initializing WebViewer', error);
      });
    },
  }
};
</script>

<style>

</style>