<template>
    <b-modal id="modal-download-app" no-close-on-backdrop hide-header hide-footer centered>
        <div class="row">
            <div class="col-md-11 d-flex flex-column">
                <span class="switch-subdomain-title">Install Jaybod Agent</span>
                <span class="my-3 switch-subdomain-text">
                    To open the file, make sure you’ve downloaded and installed Jaybod Agent, then click Open.
                </span>
                <span class="my-3 switch-subdomain-text">
                    If you haven’t downloaded and installed Jaybod Agent, please click Download.
                </span>
                <div class="d-flex justify-content-end align-items-center mt-3 download-apps-btn">
                    <button @click="downloadApps()" class="ml-3 download-apps">Download</button>
                    <button @click="openFileApp()" class="ml-3 open-file">Open</button>
                </div>
            </div>
            <div class="col-md-1">
                <img src="@/assets/images/icon/icon-cross-toast.svg" class="cursor-pointer" alt="" @click="close()">
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
    },
    methods: {
        close(){
            this.$bvModal.hide('modal-download-app')
        },
        downloadApps(){
            const userAgent = navigator.userAgent.toLowerCase()
            let urlDownload
            if (userAgent.includes('windows')) {
                urlDownload = `${process.env.VUE_APP_LINK_JAYBOD_AGENT_WINDOWS}`
            } else if (userAgent.includes('macos') || userAgent.includes('mac')) {
                urlDownload = `${process.env.VUE_APP_LINK_JAYBOD_AGENT_MAC}`
            }
            window.location.href = urlDownload
            this.$bvModal.hide('modal-download-app')
        },
        openFileApp(){
            let file = this.item;
            const appUrl = `jaybod://download/success?hash_name=${file.hash_name}&directory=${file.directory}&file_name=${file.file_name}&extension=${file.extension}&_id=${file._id}&location=${file.location}`;
            window.location.href = appUrl
            
            this.$bvModal.hide('modal-download-app')
        }
    }
}
</script>

<style>
.split-alert-text-info{
    display: flex;
}
</style>