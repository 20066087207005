
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
        linkFile(){
            return this.$store.getters["storage/linkS3"]
        }
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            counter : 0
        };
    },
    methods:{
      cancel(){
        this.$store.commit("share/SET_SHOWFILE",false);
        this.$root.$emit('bv::hide::modal', 'modalDownload');
      },
      download(){
        if(this.item.isFolder){
            this.$store.dispatch("storage/downloadFolder",
            {
                id : this.item._id,
                file_name : this.item.file_name+".zip"
            }
            );
        }else{
            const link = document.createElement("a");
            link.href = this.linkFile;
            link.setAttribute("download", (this.item.file_name.split('.').pop() == this.item.extension) ? this.item.file_name : this.item.file_name+"."+this.item.extension);
            link.style.display = 'none'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        }
        this.$root.$emit('bv::hide::modal', 'modalDownload');
      }
    }
};
</script>
<template>
    <b-modal id="modalDownload" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Donwload File">
        <div v-if="item != null">
            <h2 class="modal-title text-dark bold-normal default-text-style">Download 1 item?</h2>
            <p class="mt-3 default-text-style">Are you sure you want to download this item?</p>
            <div class="d-flex justify-content-end align-items-center close-and-submit-enterprise">
                <span class="mr-3" @click="cancel()">Cancel</span>
                <button @click="download()">
                    Download
                </button>
            </div>
        </div>
    </b-modal>
</template>